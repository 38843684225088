@import "_base.scss";
@import "../src/fca-app-theme.scss";
// $fa-font-path: "../node_modules/font-awesome/fonts";
@import "../node_modules/font-awesome/scss/font-awesome.scss";
@import url(//fonts.googleapis.com/css?family=Encode+Sans);
// @import "../node_modules/fortawesome/fontawesome-free/scss/fontawesome";

* {
  font-family: 'Encode Sans'
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  min-width: 80%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0 auto;
}

:not(:root):-webkit-full-screen::backdrop {
  background: $white;
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

.mat-button-toggle-group .mat-button-toggle-label-content {
  line-height: 32px !important;
}

/*Jeep styless
/* label style */
.mat-tab-label {
  background: #959995;
  color: white;
  font-size: 14px;
  font-weight: bold
}

/* focus style */
// .mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus, .mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus, .mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus, .mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus{
//   // background: rgb(12, 66, 15);
//   background-color: #39b739b8;
//     font-weight: bolder;
//     font-family: sans-serif;
// }
// /* ink bar style */
// .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
//   // background: rgb(0, 204, 255);
//   background-color: #39b739b8;
//   height: 2px;
// }
.mat-tab-label.mat-tab-label-active {
  background-color: #39b739b8;
  font-weight: bolder;
  font-family: sans-serif;
}